export interface ObservationType {
  id: string;
  code: string;
  title: string;
  description: string;
  chartDatasets: {
    label: string;
    code?: string;
    unit: string;
    backgroundColor: string;
    borderColor: string;
  }[];
}

const backgroundColor = '#239ea1';  // orange[5]
const borderColor = '#239ea1';      // sand[8]
const secondBackgroundColor = '#CA6702';  // orange[8]
const secondBorderColor = '#CA6702';      // orange[9]

export const measurementsMeta: Record<string, ObservationType> = {
  'blood-pressure': {
    id: 'blood-pressure',
    code: '85354-9',
    title: 'Blood Pressure',
    description:
      'Your blood pressure is the pressure exerted on the walls of your blood vessels.',
    chartDatasets: [
      {
        label: 'Diastolic',
        code: '8462-4',
        unit: 'mm[Hg]',
        backgroundColor: secondBackgroundColor,
        borderColor: secondBorderColor,
      },
      {
        label: 'Systolic',
        code: '8480-6',
        unit: 'mm[Hg]',
        backgroundColor,
        borderColor,
      },
    ],
  },
  'heart-rate': {
    id: 'heart-rate',
    code: '8867-4',
    title: 'Heart Rate',
    description: 'Your heart rate is highly dependent on both your bodily state in the moment and your overall cardiovascular health. Resting heart rate is the best measure, taken after sitting still at a consistent time each day.',
    chartDatasets: [
      {
        label: 'Heart Rate',
        unit: 'beats/minute',
        backgroundColor,
        borderColor,
      },
    ],
  },
  weight: {
    id: 'weight',
    code: '29463-7',
    title: 'Weight',
    description: 'Your weight varies by time of day. Be sure to take a measurement at a consistent time to best track your progress.',
    chartDatasets: [
      {
        label: 'Weight',
        unit: 'lbs',
        backgroundColor,
        borderColor,
      },
    ],
  },
};
