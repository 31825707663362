import React, { useEffect, useCallback } from 'react';
import { registerBlockType } from '@quillforms/blocks';
import { useBlockTheme } from '@quillforms/renderer-core';
import { ThemeIcon } from '@mantine/core';
import { css } from 'emotion';
import * as TablerIcons from '@tabler/icons-react';

type AnimationType = 'spinning' | 'bouncing' | 'spin-bounce' | 'none';

interface LoadingBlockAttributes {
  label: string;
  headline: string;
  subHead: string;
  iconName: string;
  iconSize: number;
  iconColor: string;
  useIconBackground: boolean;
  iconBackgroundColor: string;
  animationType: AnimationType;
  autoAdvance: boolean;
  autoAdvanceDelay: number;
  themeId?: number;
}

const getAnimationStyles = (animationType: AnimationType) => {
  switch (animationType) {
    case 'spinning':
      return css`
        animation: spin 1s linear infinite;
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `;
    case 'bouncing':
      return css`
        animation: bounce 1s ease infinite;
        @keyframes bounce {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
        }
      `;
    case 'spin-bounce':
      return css`
        animation: spin-bounce 4s ease infinite;
        @keyframes spin-bounce {
          0%, 49% {
            transform: rotate(0deg);
          }
          25% {
            transform: rotate(360deg); 
          }
          50%, 100% {
            transform: translateY(0);
          }
          75% {
            transform: translateY(-6px);
          }
        }
      `;
    default:
      return '';
  }
};

const LoadingBlock = ({
  attributes,
  setIsValid,
  setIsAnswered,
}: {
  attributes: LoadingBlockAttributes;
  setIsValid: (isValid: boolean) => void;
  setIsAnswered: (isAnswered: boolean) => void;
}) => {
  const theme = useBlockTheme(attributes?.themeId);
  

useEffect(() => {
    setIsValid(true);
    setIsAnswered(true);
    

    }, [setIsValid, setIsAnswered]);

  const IconComponent = (TablerIcons as any)[attributes.iconName || 'IconLoader2'];

  const IconElement = (
    <IconComponent
      size={attributes.iconSize || 48}
      color={attributes.iconColor || theme.answersColor}
      className={getAnimationStyles(attributes.animationType)}
    />
  );

  return (
  <div
    className={css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: start;
      color: ${theme.answersColor};
      margin-bottom: 50px;
    `}
  >
    <div
      className={css`
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
      `}
    >
      {attributes.useIconBackground ? (
        <ThemeIcon 
          size={attributes.iconSize ? attributes.iconSize + 20 : 68}
          radius="xl"
          color={attributes.iconBackgroundColor || 'blue'}
        >
          {IconElement}
        </ThemeIcon>
      ) : IconElement}

      <h2
        className={css`
          font-size: 1.5rem;
          font-weight: 600;
          margin: 0; /* Remove default margin */
        `}
      >
        {attributes.headline}
      </h2>
    </div>
    
    <p
      className={css`
        margin-bottom: 2rem;
        opacity: 0.8;
      `}
    >
      {attributes.subHead}
    </p>
  </div>
);
};

registerBlockType("loading-block", {
  supports: {
    editable: false,
  },
  attributes: {
    label: {
        type: "string",
        default: "&nbsp;"
    },
    headline: {
      type: "string",
      default: "Loading..."
    },
    subHead: {
      type: "string", 
      default: "Please wait while we process your information"
    },
    iconName: {
      type: "string",
      default: "IconLoader2"
    },
    iconSize: {
      type: "number",
      default: 48
    },
    iconColor: {
      type: "string",
      default: "#CA6702"
    },
    useIconBackground: {
      type: "boolean",
      default: false
    },
    iconBackgroundColor: {
      type: "string",
      default: "#005f73"
    },
    animationType: {
      type: "string",
      default: "spinning"
    },
    autoAdvance: {
        type: "boolean",
        default: false,
    },
    autoAdvanceDelay: {
        type: "number",
        default: 2,
    }
  },
  display: LoadingBlock as React.FC<any>
});

export default LoadingBlock;