import { Box, Group, Text, Container, Card, Button, Modal } from '@mantine/core';
import { DocumentReference } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';
import { IconArrowLeft, IconFileText, IconMessage } from '@tabler/icons-react';
import { FeedbackForm } from '../feedback/FeedbackForm';
import { Interaction } from '../feedback/types';

export function FollowUpDetail(): JSX.Element {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const { followUpId } = useParams();
  const [decodedContent, setDecodedContent] = useState<string>('');
  const resource: DocumentReference = medplum.readResource('DocumentReference', followUpId as string).read();
  const taskId = resource.context?.related?.[0]?.reference || followUpId;

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const followUpInteraction: Interaction = {
    id: taskId as string,
    type: 'task',
    description: resource.content?.[0]?.attachment?.title || 'Follow Up',
    timestamp: resource.meta?.lastUpdated || '',
    resourceType: 'Task'
  };

  useEffect(() => {
    if (resource.content?.[0]?.attachment?.data) {
      const base64Content = resource.content[0].attachment.data;
      const decodedString = atob(base64Content);
      setDecodedContent(decodedString);
    }
  }, [resource]);

  return (
    <Container size="lg" py={10}>
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section inheritPadding py={4} bg="gray.2">
          <Group justify="space-between" align="center">
            <Text c="gray">{resource.content[0].attachment.title}</Text>
          </Group>
        </Card.Section>

        {/* Content */}
        <Card.Section inheritPadding py="md">
          <ReactMarkdown>{decodedContent}</ReactMarkdown>
          <Button
              variant="light"
              size="xs"
              leftSection={<IconMessage size={16} />}
              onClick={() => setShowFeedbackModal(true)}
            >
              Provide Feedback
            </Button>
        </Card.Section>
        <Modal
          opened={showFeedbackModal}
          onClose={() => setShowFeedbackModal(false)}
          title="Provide Feedback"
          size="lg"
        >
          <FeedbackForm
            selectedInteraction={followUpInteraction}
            onSubmit={() => setShowFeedbackModal(false)}
          />
        </Modal>
      </Card>
    </Container>
  );
}