import { AppShell } from '@mantine/core';
import { ErrorBoundary, useMedplum } from '@medplum/react';
import { Suspense, useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Loading } from './components/Loading';
import { RegisterPage } from './pages/registration/RegisterPage';
import { SignInPage } from './pages/registration/SignInPage';
import { ResetPasswordPage } from './pages/registration/ResetPasswordPage';
import { NewProfilePage } from './pages/NewProfilePage';
import { getConfig } from './config'
import BellyFatSurveyPage from './pages/onboarding/BellyFatIntake';
import { ProfileHeader } from './pages/onboarding/ProfileHeader';
import HeartHealthSurveyPage from './pages/onboarding/HeartHealthIntake';
import { AccountPage } from './pages/account';
import { Profile } from './pages/account/Profile';
import { Provider } from './pages/account/Provider';
import { HealthRecord } from './pages/health-record';
import { Measurement } from './pages/health-record/Measurement';
import { measurementsMeta } from './pages/health-record/Measurement.data';
import { Medications } from './pages/health-record/Medications';
import { Vitals } from './pages/health-record/Vitals';
import { TasksPage } from './pages/TasksPage';
import FirstCall from './pages/onboarding/FirstCall';
import AddContact from './pages/onboarding/AddContact';
import SelectPlan from './pages/onboarding/SelectPlan';
import { useSubscription } from './hooks/subscriptionManagement';
import { FormsPage } from './pages/FormsPage';
import { SignOutPage } from './pages/registration/SignOutPage';
import { SetPasswordPage } from './pages/registration/SetPasswordPage';
import { FollowUpDetail } from './pages/followups/FollowUpDetail';
import { BackHeader } from './components/BackHeader';
import { MatomoRouteTracker } from './common/MatomoRouteTracker';
import BloodPressureSurveyPage from './pages/onboarding/BloodPressureIntake';



interface RouteConfig {
  path: string;
  element: JSX.Element;
  layout: 'full' | 'simple' | 'back';
  children?: RouteConfig[];
  public?: boolean;
}


const publicRoutes: RouteConfig[] = [
  { path: '/profile', element: <NewProfilePage />, layout: 'simple', public: true },
  { path: '/profile-heart', element: <HeartHealthSurveyPage />, layout: 'simple', public: true },
  { path: '/profile-bf', element: <BellyFatSurveyPage />, layout: 'simple', public: true },
  { path: '/profile-bp', element: <BloodPressureSurveyPage />, layout: 'simple', public: true },
  { path: '/profile-bp/*', element: <BloodPressureSurveyPage />, layout: 'simple', public: true },
];

const authenticatedRoutes: RouteConfig[] = [
  { path: '/', element: <TasksPage/>, layout: 'full' },
  { path: '/account', element: <AccountPage />, layout: 'full', children: [
    { path: 'profile', element: <Profile/>, layout: 'full' },
    { path: 'provider', element: <Provider />, layout: 'full' },
  ]},
  { path: '/health-record', element: <HealthRecord />, layout: 'full', children: [
    { path: 'vitals', element: <Vitals />, layout: 'full' },
    { path: 'medications', element: <Medications />, layout: 'full' },
    ...Object.keys(measurementsMeta).map((measurementId): RouteConfig => ({
      path: `vitals/${measurementId}`,
      element: <Measurement measurementType={measurementId} />,
      layout: 'full'
    })),
  ]},
  { path: '/followups/:followUpId', element: <FollowUpDetail />, layout: 'back' },
  { path: '/cf/:followUpId', element: <FollowUpDetail />, layout: 'back' },
  { path: '/first-call', element: <FirstCall />, layout: 'simple' },
  { path: '/add-contact', element: <AddContact />, layout: 'simple' },
  { path: '/profile', element: <NewProfilePage />, layout: 'simple', public: true },
  { path: '/profile-heart', element: <HeartHealthSurveyPage />, layout: 'simple', public: true },
  { path: '/profile-bf', element: <BellyFatSurveyPage />, layout: 'simple', public: true },
  { path: 'forms/:formId', element: <FormsPage />, layout: 'simple'},
  { path: 'select-plan', element: <SelectPlan />, layout: 'simple'},
  
  { path: 'signout', element: <SignOutPage />, layout: 'simple'}
];

function LayoutWrapper({ children, layout }: { children: React.ReactNode; layout: 'full' | 'simple' | 'back'}) {
  const location = useLocation(); // Add this line to get current location
  
  // Check if current path starts with /profile
  const isProfilePath = location.pathname.startsWith('/profile');

  return (
    <AppShell header={{ height: 80 }}>
    {layout === 'full' ? (
      <><Header /> <AppShell.Main>{children}</AppShell.Main></>
    ) : layout === 'back' ? (
      <><BackHeader /> <AppShell.Main>{children}</AppShell.Main></>
    ) : (
      <><ProfileHeader /> {children}</>
    )}
    
     {/* Only render Footer if not on profile paths */}
     {!isProfilePath && <Footer />}
  </AppShell>
  );
}

function RouteWithLayout({ route }: { route: RouteConfig }) {
  if (route.children) {
    return (
      <LayoutWrapper layout={route.layout}>
        {route.element}
      </LayoutWrapper>
    );
  }
  
  // For leaf routes
  return (
    <LayoutWrapper layout={route.layout}>
      {route.element}
    </LayoutWrapper>
  );
}

export function App(): JSX.Element | null {
  const medplum = useMedplum();
  const location = useLocation();
  const profile = medplum.getProfile();
  const config = getConfig();
  const { hasActivePlan, isLoadingAccount, subscription } = useSubscription();

  console.log('App render state:', { 
    isLoading: medplum.isLoading(), 
    hasActivePlan, 
    isAuthenticated: !!profile 
  });

  if (medplum.isLoading() || isLoadingAccount) {
    return <Loading />;
  }
  
  const isAuthenticated = profile;

  // Log the routing decision
  console.log('Routing decision:', {
    isAuthenticated,
    hasActivePlan,
    shouldShowSelectPlan: isAuthenticated && !hasActivePlan
  });

  if (!isAuthenticated) {
    return (
      <>
      <MatomoRouteTracker />
      <Routes>
        <Route path="/" element={<SignInPage />} />
        <Route path="signin" element={<SignInPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="resetpassword" element={<ResetPasswordPage />} />
        <Route path="setpassword/:id/:secret" element={<SetPasswordPage />} />
        {publicRoutes.map((route) => (
          <Route
            path={route.path}
            element={<RouteWithLayout route={route} />}
          />
        ))}  
        <Route path="*" element={<Navigate replace to={`/signin?next=${encodeURIComponent(location.pathname + location.search)}`} />} />
      </Routes>
      </>
    );
  }

  if ( hasActivePlan === null ) {
    return (
      <>
      <MatomoRouteTracker />
      <Routes>
        <Route path="signout" element={<SignOutPage />} />
        <Route path="select-plan" element={<RouteWithLayout route={{ path: 'select-plan', element: <SelectPlan />, layout: 'simple' }} />} />
        <Route path="*" element={<Loading />} />
      </Routes>
      </>
    );
  }
  
   // Handle no active plan state - be explicit about allowed routes
  if (hasActivePlan === false) {
    return (
      <>
      <MatomoRouteTracker />
      <Routes>
        <Route path="signout" element={<SignOutPage />} />
        <Route path="*" element={<RouteWithLayout route={{ path: 'select-plan', element: <SelectPlan />, layout: 'simple' }} />} />
      </Routes>
      </>
    );
  }

  // Allow for urls that are env specific
  authenticatedRoutes.push({ path: 'bps', element: <Navigate replace to={`/forms/${config.bpWeightSurvey}${location.search}`} />, layout: 'simple'})
  
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
      <MatomoRouteTracker />
        <Routes>
          {authenticatedRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<RouteWithLayout route={route} />}
            >
              {route.children?.map((childRoute) => (
                <Route
                  key={childRoute.path}
                  path={childRoute.path}
                  element={childRoute.element}
                />
              ))}
            </Route>
          ))}
          <Route path="*" element={<Navigate replace to={'/'} />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}
