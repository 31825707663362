import { MantineColorsTuple, MantineProvider, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { MedplumClient } from '@medplum/core';
import { MedplumProvider } from '@medplum/react';
import '@medplum/react/styles.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { getConfig } from './config';
import { MatomoRouteTracker } from './common/MatomoRouteTracker';
import { initMatomo } from './common/useMatomo';

// Initialize Matomo as early as possible
initMatomo();

const config = getConfig();

const medplum = new MedplumClient({
  // To run Member App locally, you can set the baseURL in this constructor
  baseUrl: config.baseUrl,
  clientId: config.clientId,
  onUnauthenticated: () => {
    if (window.location.pathname !== '/signin' && window.location.pathname !== '/oauth') {
      window.location.href = '/signin?next=' + encodeURIComponent(window.location.pathname + window.location.search);
    }
  },
});

const darkteal: MantineColorsTuple = [
  "#e6e7e8",
  "#b3b8ba",
  "#80898c",
  "#4d595e",
  "#1a2a30",
  "#001219",
  "#001017",
  "#000e14",
  "#000d12",
  "#000b0f"
];

const teal: MantineColorsTuple = [
  "#e6eff1",
  "#b3cfd5",
  "#80afb9",
  "#4d8f9d",
  "#1a6f81",
  "#005F73",
  "#005668",
  "#004c5c",
  "#004351",
  "#003945"
];

const lightteal: MantineColorsTuple = [
  "#e7f4f5",
  "#b6dfe0",
  "#85c9cb",
  "#54b3b6",
  "#239ea1",
  "#0A9396",
  "#098487",
  "#087678",
  "#076769",
  "#06585a"
];

const mint: MantineColorsTuple = [
  "#f4fbf8",
  "#dff2eb",
  "#cae9de",
  "#b4e0d1",
  "#9fd7c4",
  "#94D2BD",
  "#85bdaa",
  "#76a897",
  "#689384",
  "#597e71"
];

const sand: MantineColorsTuple = [
  "#fdfbf6",
  "#f8f3e4",
  "#f4ecd3",
  "#f0e4c1",
  "#ebdcaf",
  "#E9D8A6",
  "#d2c295",
  "#baad85",
  "#a39774",
  "#8c8264"
];

const orange: MantineColorsTuple = [
  "#fff4e5",
  "#ffe8d0",
  "#fdce9f",
  "#fcb36a",
  "#fb9c3e",
  "#fb8e23",
  "#fb8615",
  "#e07309",
  "#c76602",
  "#ae5600"
];

const rust: MantineColorsTuple = [
  "#faf0e6",
  "#efd1b3",
  "#e5b381",
  "#da954e",
  "#cf761b",
  "#CA6702",
  "#b65d02",
  "#a25202",
  "#8d4801",
  "#793e01"
];

const coral: MantineColorsTuple = [
  "#f8ece6",
  "#ebc5b3",
  "#dd9f81",
  "#cf784f",
  "#c2511c",
  "#BB3E03",
  "#a83803",
  "#963202",
  "#832b02",
  "#702502"
];

const red: MantineColorsTuple = [
  "#f7e9e7",
  "#e7bcb8",
  "#d79089",
  "#c66359",
  "#b6362a",
  "#AE2012",
  "#9d1d10",
  "#8b1a0e",
  "#7a160d",
  "#68130b"
];

const darkred: MantineColorsTuple = [
  "#f5e9e9",
  "#e1bdbe",
  "#cd9193",
  "#b96467",
  "#a5383c",
  "#9B2226",
  "#8c1f22",
  "#7c1b1e",
  "#6d181b",
  "#5d1417"
];

const theme = createTheme({
  primaryColor: 'orange',
  colors: {
    darkteal,
    teal,
    lightteal,
    mint,
    sand,
    orange,
    rust,
    coral,
    red,
    darkred,
  },
  primaryShade: 8,
  white: '#F9F7F3',
  fontFamily: 'Urbanist, sans-serif',
  fontSizes: {
    xs: '0.6875rem',
    sm: '0.875rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
    xxl: '1.4rem'
  },
  components: {
    Container: {
      defaultProps: {
        size: 1200,
      },
    },
  },
  /** Controls various styles of h1-h6 elements, used in TypographyStylesProvider and Title components */
  headings: {
    fontFamily: 'Urbanist, sans-serif'
  }
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <MedplumProvider medplum={medplum}>
        <MantineProvider theme={theme}>
          <App />
        </MantineProvider>
      </MedplumProvider>
    </BrowserRouter>
  </StrictMode>
);
