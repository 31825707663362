import { Container, Group, useMantineTheme } from '@mantine/core';
import { Suspense, useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { SideMenu } from '../../components/SideMenu';
import { measurementsMeta } from './Measurement.data';

// Add the useWindowWidth hook we see used in other files
function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

const sideMenu = {
  title: 'Health Record',
  menu: [
    {
      name: 'Vitals',
      href: '/health-record/vitals',
      subMenu: Object.values(measurementsMeta).map(({ title, id }) => ({
        name: title,
        href: `/health-record/vitals/${id}`,
      })),
    },
  ],
};

export function HealthRecord(): JSX.Element {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 768;
  const theme = useMantineTheme();

  return (
    <Container>
      {!isMobile ? (
        // Desktop view with side menu
        <Group align="top">
          <SideMenu {...sideMenu} />
          <div style={{ width: 800, flex: 800 }}>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </div>
        </Group>
      ) : (
        // Mobile view with bottom navigation
        <>
          <div style={{ paddingBottom: '80px' }}>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </div>
          <div style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            background: theme.white,
            borderTop: `1px solid ${theme.colors.gray[2]}`,
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            zIndex: 1000
          }}>
            {Object.values(measurementsMeta).map(({ title, id }) => (
              <Link 
                key={id}
                to={`/health-record/vitals/${id}`}
                style={{
                  textDecoration: 'none',
                  color: location.pathname.includes(`/vitals/${id}`) ? 
                    theme.colors.orange[7] : theme.colors.gray[7],
                  padding: '8px 12px',
                  borderRadius: '4px',
                  fontWeight: location.pathname.includes(`/vitals/${id}`) ? 600 : 400,
                  borderBottom: location.pathname.includes(`/vitals/${id}`) ? 
                    `2px solid ${theme.colors.orange[7]}` : 'none',
                  transition: 'all 0.2s ease'
                }}
              >
                {title}
              </Link>
            ))}
          </div>
        </>
      )}
    </Container>
  );
}